import React from "react";

type Lang = 'pt' | 'es' | 'en' | 'it';
interface ModalProps {
  link: string;
  lang?: Lang
}

export default function Modal({ link, lang = 'en' }: ModalProps) {
  const textByLanguage: Record<string, any> = {
    en: {
      close: 'Close',
      cookiePolicy: 'Cookie Policy',
      main: 'This website uses cookies to personalize content and ads, provide social media features and analyze our traffic. By clicking Accept, you agree to the use of cookies. For more information, please visit our',
      accept: 'Accept'
    },
    it: {
      close: 'Per chiudere',
      cookiePolicy: 'Politica sui Cookie',
      main: "Questo sito Web utilizza i cookie per personalizzare contenuti e annunci, fornire funzionalità dei social media e analizzare il nostro traffico. Facendo clic su Accetta, accetta l'uso dei cookie. Per ulteriori informazioni, visitare il nostro",
      accept: 'Accettare'
    },
  }
  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-end">
      <div className="w-[90%] md:w-[80%] lg:w-[50%] min-h-[300px] md:min-h-[230px] bg-white shadow-md rounded-2xl mb-[70px] md:mb-5 flex flex-col justify-center items center px-8 pb-2">
        <a
          className="btn-lp text-blue-400 text-[16px] w-full h-4 mt-2 font-nunito text-right"
          href={link}
        >
          {textByLanguage[lang].close}
        </a>
        <h1 
          className="font-nunito text-2xl font-bold mb-3"
        >
          {textByLanguage[lang].cookiePolicy}
        </h1>
        <p
          className="font-nunito  mb-3"
        >
          {textByLanguage[lang].main + ' '}<a className="btn-lp cursor-pointer text-blue-500" href={link}>{textByLanguage[lang].cookiePolicy}</a>
        </p>
        <div 
          className="w-full h-[50px] mb-3"
        >
          <a
            className="btn-lp bg-blue-500 w-[150px] h-10 text-white text-bold text-[20px] font-nunito font-bold rounded-xl mt-3 shadow-lg shadow-blue-200 text-center flex justify-center items-center"
            href={link}
          >
            {textByLanguage[lang].accept}
          </a>
        </div>
      </div>
    </div>
  )
}