export default function Home() {
  return (
    <div className="w-full h-screen bg-slate-100 flex justify-center">
      <div className="w-[95%] lg:w-[90%] py-4">
        <section className="flex flex-col">
          <h2 className="font-roboto font-bold text-[34px]">Products</h2>
          <div className="w-full">
            <div className="w-full">
              <a href="/sqribble">
                <div
                  className="w-full lg:w-[300px] h-[200px] bg-[url('https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/logo.png')] bg-contain bg-no-repeat rounded-2xl bg-start flex justify-center items-end bg-orange-200 shadow-xl overflow-hidden" 
                >
                    <div className="w-full h-[75px] flex justify-center items-center bg-gradient-to-t from-0% from-slate-600">
                      <h2 className="font-nunito font-bold text-xl text-left text-white drop-shadow-md">Sqribble</h2>
                    </div>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}