import Modal from "../../components/Modal";

export default function FemiPro() {
  const linkAffiliate = "http://femipro24.com/text.php#aff=Hary1967";

  return (
    <>
      <div className={`w-full h-screen flex justify-center items-center bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/img/femipro/mobile.png)] lg:bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/img/femipro/desktop.png)] lg:bg-cover`}></div>
      <Modal link={linkAffiliate} />
    </>
  )
}