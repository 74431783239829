import Modal from "../../components/Modal";

export default function Vizonic() {
  const linkAffiliate = "https://uh30a380c3uh.axdsz.pro/?target=-7EBNQCgQAAAfF0AMDp44ABQEBEREKEQkKEQ1CEQ0SAAF_YWRjb21ibwEx&al=95270&ap=-1";

  return (
    <>
      <div className={`w-full h-screen flex justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/vizonic/mobile-2.png)] lg:bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/vizonic/desktop-2.png)] bg-contain lg:bg-cover`}></div>
      <Modal 
        link={linkAffiliate} 
        lang={"it"} 
      />
    </>
  )
}