import React from "react";
import Modal from "../../../components/Modal";

export default function SqribbleMain() {
  const linkAffiliate = "https://0fe17iqnu0cmvp7j3dmwm9oodp.hop.clickbank.net/?&traffic_source=google&traffic_type=paid";

  return (
    <>
      <div className={`w-full h-screen flex justify-center items-center bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/home-mobile.png)] lg:bg-[url(https://d3tb5hw0zfiw46.cloudfront.net/img/sqribble/home-desktop.png)] bg-cover bg-center bg-no-repeat`}></div>
      <Modal link={linkAffiliate} />
    </>
  )
}