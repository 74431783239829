import Modal from "../../components/Modal";

export default function EmperorsVigorTonic() {
  const linkAffiliate = "https://emperorsvigortonic24.com/text.php#aff=wesleysantossts";

  return (
    <>
      <div className={`w-full h-screen flex justify-center items-center bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/img/emperors+vigor+tonic/mobile.png)] lg:bg-[url(https://purchasetoday.s3.us-east-1.amazonaws.com/img/emperors+vigor+tonic/desktop.png)] bg-contain lg:bg-cover`}></div>
      <Modal link={linkAffiliate} />
    </>
  )
}