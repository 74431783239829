import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import Routes from './routes';

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={Routes()} />
    </React.StrictMode>
  );
}

export default App;
